import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import SquareIcon from "@mui/icons-material/Square";

export default function AboutUsComp() {
  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.greenTab} />
        <Stack direction="row" className={styles.headerContainer}>
          <Typography className={styles.tableHead}>About Us</Typography>
        </Stack>
      </Box>
      <Box className={styles.contentCont}>
        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          The platform advocates fairness, justice, and openness. We mainly
          operate fair blockchain games.
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          Welcome to AmarGaming works with more than 10,000 online live game
          dealers and slot games, all of which are verified fair games.
        </Typography>

        <Typography className={styles.list}>
          <SquareIcon className={styles.listPointer} />
          Welcome to AmarGaming supports fast deposit and withdrawal, and looks
          forward to your visit..
        </Typography>

        <Typography className={styles.warnTxt}>
          Gambling can be addictive, please play rationally.
        </Typography>

        <Typography className={styles.warnTxt}>
          Welcome to AmarGaming only accepts customers above the age of 18.
        </Typography>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.greenTab} />
        <Stack direction="row" className={styles.headerContainer}>
          <Typography className={styles.tableHead}>Customer service</Typography>
        </Stack>
      </Box>

      <Box className={styles.contentCont}>
        <Typography className={styles.list}>
          At AmarGaming, we’re committed to providing you with the best possible
          experience. If you have any questions, concerns, or feedback, our
          dedicated customer service team is here to help!
        </Typography>

        <Typography className={styles.list} fontWeight={600}>
          Contact Us
        </Typography>
        <Typography className={styles.list} fontWeight={600}>
          Email:
        </Typography>

        <Typography className={styles.list}>
          For inquiries, please reach out to us at: ✉️ amar21game@gmail.com
        </Typography>
        <Typography className={styles.list} fontWeight={600}>
          Phone:
        </Typography>

        <Typography className={styles.list}>
          You can also contact us directly: 📞 7400880791
        </Typography>
        <Typography className={styles.list}>
          Our customer service hours are Monday to Friday, 9 AM - 5 PM.
        </Typography>
      </Box>
    </>
  );
}
